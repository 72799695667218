import React from 'react'
import Layout from '../components/layouts/page'
import { StaticImage } from 'gatsby-plugin-image'
import { Helmet } from 'react-helmet'

const Quote = () => {
  return (
    <div className="page">
    <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <title>Request A Quote For Your Next Limo - Devine Ride</title>
    <meta name="description" content="Request A Quote For Limo Service in Los Angeles County, Orange County, and San Diego County."/>
    </Helmet>
    <Layout>
      <section className='bg-light'>
        <div className='container-xxl'>
          <div className='d-flex justify-content-center'>
            <div className='col-md-8 text-center py-5'>
              <div className='py-5'>
                <h1 className='display-1 fw-medium mb-3'>Request A Quote</h1>
                <p className='fs-5 mb-4'>Once you request a quote we will contact you to discuss your options.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='container-xxl'>
          <div className='d-flex justify-content-center'>
            <div className='col-lg-8 py-5'>
              <form className="bg-light p-5" name="quote" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
                <input type="hidden" name="quote" value="quote" />
                <fieldset className=''>
                  <legend className='fs-4 fw-medium mb-3'>Basic Information</legend>
                  <div className='row'>
                    <div className="mb-2 col-lg-6">
                      <label className="mb-1 w-100" for="field1">Name (required)
                      <input type="text" className="form-control form-control-lg rounded" id="field1" name="name" required/>
                      </label>
                    </div>
                    <div className="mb-2 col-lg-6">
                      <label className="mb-1 w-100" for="field2">Email (required)
                      <input type="email" className="form-control form-control-lg rounded" id="field2" name="email" required/>
                      </label>
                    </div>
                  </div>
                  <div className='row'>
                    <div className="mb-2 col-lg-6">
                    <label className="mb-1 w-100" for="field3">Phone (required)
                      <input type="text" className="form-control form-control-lg rounded" id="field3" name="phone" required/>
                      </label>
                    </div>
                    <div className="mb-2 col-lg-6">
                      <label className="mb-1 w-100" for="field4">Type of ride
                      <select className="form-select form-select-lg rounded" id="field4">
                          <option value="point to point">Point to point</option>
                          <option value="Hourly">Hourly</option>
                        </select>
                        </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-2 col-lg-6">
                      <label className="w-100" for="field5">Pickup date (required)
                      <input type="date" className="form-control form-control-lg rounded" id="field5" name="pickup date" required/>
                      </label>
                    </div>
                    <div className="mb-2 col-lg-6">
                      <label className="w-100" for="field6">Pickup time
                      <input type="time" className="form-control form-control-lg rounded" id="field6" name="pickup time"/>
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-2 col-lg-6">
                      <label className="w-100" for="field7">Pickup location (required)
                      <input type="text" className="form-control form-control-lg rounded" id="field7" name="pickup location" required/>
                      </label>
                    </div>
                    <div className="mb-2 col-lg-6">
                      <label className="w-100" for="field8">Dropoff location
                      <input type="text" className="form-control form-control-lg rounded" id="field8" name="dropoff location"/>
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-4 col-lg-6">
                      <label className="w-100" for="field9">Number of passengers (required)
                      <input type="number" className="form-control form-control-lg rounded" id="field9" name="passenger count" required/>
                      </label>
                    </div>
                  </div>
                </fieldset>
                <fieldset className='mt-4'>
                  <legend className='fs-4 fw-medium mb-3'>Vehicle Information</legend>
                  <div className='row'>
                    <div className="mb-4 col-lg-6">
                      <label for="field10" className="border border-2 border-dark d-block px-3 py-3 custom-radio-label">
                        <div className="d-flex">
                          <div>
                            <input type="radio" id="field10" name="vehicle" value="the road king"/>
                            <span className="custom-radio"></span>
                          </div>
                          <div className="d-block w-100">
                            <div className="mb-3 d-flex justify-content-between">
                              <span className="fw-medium">The Road King</span>
                              <span className="fw-medium"><i className="bi bi-person-fill fs-6"></i> 7</span>
                            </div>
                          </div>
                        </div>
                        <StaticImage 
                          src='../images/orange-county-limo.jpg' 
                          alt='Arriving at a concert in style'
                          className='w-100'
                        />
                      </label>
                    </div>
                    <div className="mb-4 col-lg-6">
                      <label for="field11" className="border border-2 border-dark d-block px-3 py-3 custom-radio-label">
                        <div className="d-flex">
                          <div>
                            <input type="radio" id="field11" name="vehicle" value="corporate shuttle"/>
                            <span className="custom-radio"></span>
                          </div>
                          <div className="d-block w-100">
                            <div className="mb-3 d-flex justify-content-between">
                              <span className="fw-medium">Corporate Shuttle</span>
                              <span className="fw-medium"><i className="bi bi-person-fill fs-6"></i> 14</span>
                            </div>
                          </div>
                        </div>
                        <StaticImage 
                          src='../images/corporate-shuttle.jpg' 
                          alt='Arriving at a concert in style'
                          className='w-100'
                        />
                      </label>
                    </div>
                  </div>
                  <div className='row'>
                    <div className="mb-4 col-lg-6">
                      <label for="field12" className="border border-2 border-dark d-block px-3 py-3 custom-radio-label">
                        <div className="d-flex">
                          <div>
                            <input type="radio" id="field12" name="vehicle" value="night rider"/>
                            <span className="custom-radio"></span>
                          </div>
                          <div className="d-block w-100">
                            <div className="mb-3 d-flex justify-content-between">
                              <span className="fw-medium">The Night Rider</span>
                              <span className="fw-medium"><i className="bi bi-person-fill fs-6"></i> 14</span>
                            </div>
                          </div>
                        </div>
                        <StaticImage 
                          src='../images/party-bus-los-angeles.jpg' 
                          alt='Arriving at a concert in style'
                          className='w-100'
                        />
                      </label>
                    </div>
                    <div className="mb-4 col-lg-6">
                      <label for="field13" className="border border-2 border-dark d-block px-3 py-3 custom-radio-label">
                        <div className="d-flex">
                          <div>
                            <input type="radio" id="field13" name="vehicle" value="weekender"/>
                            <span className="custom-radio"></span>
                          </div>
                          <div className="d-block w-100">
                            <div className="mb-3 d-flex justify-content-between">
                              <span className="fw-medium">The Weekender</span>
                              <span className="fw-medium"><i className="bi bi-person-fill fs-6"></i> 8</span>
                            </div>
                          </div>
                        </div>
                        <StaticImage 
                          src='../images/los-angeles-limo.jpg' 
                          alt='Arriving at a concert in style'
                          className='w-100'
                        />
                      </label>
                    </div>
                  </div>
                </fieldset>
                <button type="submit" className="btn btn-dark rounded mt-3">Request A Quote</button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Layout>
    </div>
  )
}

export default Quote